
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import EditTableLoader from '@/components/table/EditTableLoader.vue';
import EditTagLoader from '@/components/tag/EditTagLoader.vue';
import TagForm from '@/components/tag/TagForm.vue';
import { Tag } from '@/interfaces/models/Tag';
import Notification from '@/mixins/Notification';

const venue = namespace('venue');
const tag = namespace('tag');

@Component({
  components: { TagForm, EditTagLoader, EditTableLoader, VWrapper },
})
export default class EditTag extends mixins(Editable, Notification) {
  @venue.State('active') public venue!: Venue;

  @tag.Action('store') public store!: any;
  @tag.Action('update') public update!: any;
  @tag.Action('show') public show!: any;
  @tag.State('active') public active!: Tag;

  public $refs!: {
    form: InstanceType<typeof TagForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  get tag() {
    if (this.editing && this.active) {
      return this.active;
    }

    return null;
  }

  get title() {
    return this.editing ? 'tag.edit' : 'tag.create';
  }

  public async mounted() {
    if (this.editing) {
      this.$startLoading('tag');
      await this.show({ id: this.id });
      this.$stopLoading('tag');
    }
    if (this.editing && !this.active) {
      this.$router.push({ name: 'tag.index' });
    }
    if (!this.editing && !this.venue) {
      this.$router.push({ name: 'tag.index' });
    }
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean) => {
      if (res) {
        if (this.editing) {
          await this.update({ ...this.$refs.form.getData(), id: this.id });
        } else {
          await this.store({ ...this.$refs.form.getData(), venue: this.venue._id });
        }
        this.$router.push({ name: 'tag.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
